import React from "react";
import Layout from "../components/layout";
import TeamImage from "../images/undraw_game_day_ucx9.svg";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import BlueSlash from "../components/blueSlash";
import ContactUs from "../components/contactUs";

const team = ({ data }) => {
  const renderTeamMember = (
    fixed,
    name,
    title,
    lIText = "",
    lIUrl = "",
    para
  ) => (
    <div className="box columns">
      <figure className="column">
        <Img
          fixed={fixed}
          style={{ borderRadius: "128px", display: "block", margin: "auto" }}
        ></Img>
      </figure>
      <div className="column is-three-quarters">
        <h2>{name}</h2>
        <h4>{title}</h4>
        {lIUrl && (
          <a href={lIUrl} target="_blank" rel="noreferrer">
            {lIText}
          </a>
        )}
        <p>{para}</p>
      </div>
    </div>
  );

  return (
    <Layout>
      <BlueSlash />
      <section className="section">
        <div
          className="container"
          style={{
            position: "relative",
            width: "100%",
            marginTop: "3rem",
          }}
        >
          <img
            src={TeamImage}
            className="is-hidden-mobile"
            alt="A sketch of a man standing beside a web design"
            style={{
              position: "absolute",
              height: "550px",
              top: 300,
              right: -200,
              zIndex: -100,
            }}
          />
          <h1 className="title is-size-1 has-text-primary">Meet the Team</h1>
          <p className="subtitle" style={{ marginBottom: "5rem" }}>
            Get to know the developers behind our innovative agency
          </p>
          <div className="columns is-multiline">
            <div className="box is-two-thirds-desktop column">
              {renderTeamMember(
                data.justin.childImageSharp.fixed,
                "Justin Jones",
                "Software Architect/Founder",
                "linkedin.com/in/justin-jones-7b26805a",
                "https://www.linkedin.com/in/justin-jones-7b26805a/",
                "From working on the NASA Mars Rover to in-vehicle infotainment systems, I’ve had a vast software development career utilizing many languages. My customers are always happy because the software I create helps them add real value to their organizations. I’m constantly following up and reaching out personally to my customers to ensure my work continues to add value. I stay up to date with the latest technology trends by reading tech blog posts, attending technology conferences, and experimenting during my free time. I don't just build custom software solutions, I build custom software solutions that work!"
              )}
            </div>
            <div className="column is-one-third-desktop"></div>
            <div className="box is-two-thirds-desktop column">
              {renderTeamMember(
                data.brandon.childImageSharp.fixed,
                "Brandon Veth",
                "Developer/Founder",
                "linkedin.com/in/brandon-veth-aa6a2b88",
                "https://www.linkedin.com/in/brandon-veth-aa6a2b88/",
                "I have a diverse background from owning my own e-commerce business to working for the US Forest Service in the NorthWest US to developing software. I’ve been on every side of working with contractors and learned a lot from it. I love helping people solve seemingly impossible problems with technology. In my years of experience with technology, I have never grown tired of seeing people’s joy when it just works, exactly how we said it would."
              )}
            </div>
            <div className="column is-one-third-desktop"></div>
            <div className="box is-two-thirds-desktop column">
              {renderTeamMember(
                data.scott.childImageSharp.fixed,
                "Scott",
                "Dog",
                "",
                "",
                "Honestly, I’m a dog. I love the simple things in life like long walks, a game of fetch, or even tug of war. I think my calm energy helps those around me to relax and focus. I’m always excited to see you and sad to see you leave."
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ paddingTop: "6rem" }}>
        <div className="container">
          <div className="columns">
            <h2 className="title is-size-1 has-text-primary column is-one-quarter">
              Our Story
            </h2>
            <div className="column subtitle">
              <p style={{ marginBottom: "1.5rem", lineHeight: "1.6" }}>
                Peak Inertia was founded by two developers who met on the job in
                Detroit, MI. We noticed how difficult working with contractors
                could be. They were hard to get ahold of, they would build the
                wrong things, they would say they could do things they couldn’t,
                they ran over budget, they missed deadlines, and sometimes they
                just didn’t deliver. We knew we could do it better.
              </p>
              <p style={{ marginBottom: "1.5rem", lineHeight: "1.6" }}>
                To solve the problems that most agencies have, we stay small.
                When you call or email, the developer who is building your
                software will respond. Our agency still delivers large projects
                on time thanks to our use of modern technology. With serverless,
                static sites, and other innovations a few developers can now
                deliver what an entire team could not. We work smarter.
              </p>{" "}
              <p style={{ marginBottom: "1.5rem", lineHeight: "1.6" }}>
                We started by choosing technology that works for us, our
                clients, and our client’s customers. Then we focused on building
                relationships with clients as opposed to getting as many as
                possible. We want to spend our time building software not
                looking for clients, so we strive to make every client a
                satisfied client.
              </p>
              <p style={{ lineHeight: "1.6" }}>
                If this sounds like the kind of team you want to work with then
                please reach out to us with the form below, an email, or even a
                call. As of now, we still have space for new clients.
              </p>
            </div>
          </div>
        </div>
      </section>
      <ContactUs />
    </Layout>
  );
};

export default team;

export const query = graphql`
  {
    brandon: file(relativePath: { eq: "brandon-veth-profile.jpg" }) {
      childImageSharp {
        fixed(width: 128, height: 128) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    justin: file(relativePath: { eq: "justin-jones-profile.png" }) {
      childImageSharp {
        fixed(width: 128, height: 128) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    scott: file(relativePath: { eq: "IMG_0763.jpeg" }) {
      childImageSharp {
        fixed(width: 128, height: 128, rotate: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
